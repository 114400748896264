import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// import VueLazyload from 'vue-lazyload'
import "lib-flexible";
// import Qs from 'qs'
import MD5 from "js-md5";
import Meta from "vue-meta";
import axiosCont from "@/utils/axios";
import { Base64 } from "js-base64";
Vue.use(Base64);
Vue.use(Meta);
Vue.config.productionTip = false;
Vue.prototype.$axios = axiosCont; //引用axios
// Vue.prototype.$axios = Axios.create({
//   // baseURL: "https://zgkccaseapi.kjcxchina.com/api", // url = base url + request url
//   // withCredentials: true, // send cookies when cross-domain requests
//   timeout: 10000 // request timeout
// })
Vue.prototype.$MD5 = MD5;

import scroll from "vue-seamless-scroll";
Vue.use(scroll);
import "element-ui/lib/theme-chalk/index.css";
import element from "./element/index";
Vue.use(element);
// Vue.use(VueLazyload)
new Vue({
  router,
  // Qs,
  store,
  render: (h) => h(App),
}).$mount("#app");
